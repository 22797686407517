import { AuthService } from './auth.service';
import { HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse, HttpParams, HttpParameterCodec } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError as observableThrowError, throwError } from "rxjs";
import { tap, catchError, finalize } from "rxjs/operators";
import { Router } from "@angular/router";
import { HttpMonitor } from '../helpers/http.monitor';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';
import { ToastService } from './toast-service';
@Injectable({
  providedIn: "root",
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private _storageService: StorageService,
    private router: Router,
    private _toasterService: ToastService,
    private _authService: AuthService,
    private httpMonitor: HttpMonitor
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this._storageService.getStorageItem("accessToken");

    const params = new HttpParams({
      encoder: new CustomEncoder(),
      fromString: req.params.toString(),
    });

    let headers: HttpHeaders = req.headers.set("x-functions-key", environment.xFunctionsKey);
    let cloned = req.clone({
      params,
      headers
    });

    if (token) {
      cloned = req.clone({
        headers: headers.set("Authorization", `Bearer ${token}`),
        params,
      });
    }

    this.httpMonitor.addRequest();
    return next.handle(cloned).pipe(
      tap((res) => {
        if (res instanceof HttpResponse) {
        }
      }), finalize(() => {
        this.httpMonitor.removeRequest();
      }),
      catchError((err: HttpErrorResponse) => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 400:
              this._toasterService.show({ message: err.error.errors[0].errorMessage, panelClass: 'bg-danger text-light', delay: 5000 });
              break;
            case 401:
              this.logout(true);
              this._toasterService.show({ message: err.error.message, panelClass: 'bg-danger text-light', delay: 5000 });
              break;
            case 404:
              this._toasterService.show({ message: err.error.message, panelClass: 'bg-danger text-light', delay: 5000 });
              break;
            case 500:
              this._toasterService.show({ message: 'Internal Server Error', panelClass: 'bg-danger text-light', delay: 5000 });
              //this.logout(true);
              break;
            default:
              return observableThrowError(err);
          }
        }
        return throwError(err);
      })
    );
  }

  logout(sessionExpiry?: boolean) {
    this._storageService.clearAll();
    this._authService.isLoggedIn.next(false);
    this._authService.$user.next(null);
    if (sessionExpiry)
      this.router.navigate(["/login"]);
    else
      this.router.navigate(["/401"]);
  }

}

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
