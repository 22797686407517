<nav class="sidebar">
    <ul class="nav-list">
        @for (item of menuItems; track $index) {
        <li #activeLink="routerLinkActive" [routerLink]="[item.link]" [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{exact:true}" class="nav-item">
            <a class="nav-link">
                <img [src]="item.icon" [alt]="item.label + ' icon'" class="nav-icon">
                <span>{{ item.label }}</span>
            </a>
        </li>
        }
    </ul>
</nav>
<div class="separator"></div>