import { takeUntil, finalize } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, OnDestroy {

    $unsubscribe = new Subject<void>();
    constructor(private router: Router, private _authService: AuthService, private _storageService: StorageService) { }

    async canActivate() {
        let status = false;
        status = await new Promise((resolve, reject) => {
            this._authService.isLoggedIn.subscribe((result) => {
                if (!result) {
                    if (this._storageService.getStorageItem('accessToken')) {
                        this._authService.status().pipe(
                            takeUntil(this.$unsubscribe),
                            finalize(() => {
                                this.$unsubscribe.next();
                                this.$unsubscribe.complete();
                            })
                        ).subscribe((user: any) => {
                            status = (user) ? true : false;
                            return resolve(status);
                        }, (err) => {
                            this.router.navigate(['/login']);
                        });
                    } else {
                        this.router.navigate(['/login']);
                        return resolve(false);
                    }
                } else {
                    // if (this._authService.$user.value?.profileCompleted == false) {
                    //     this.router.navigate(['/setup']);
                    //     return resolve(false);
                    // } else {
                    //     return resolve(true);
                    // }
                    return resolve(true);
                }
            });
        });
        return status;
    }

    ngOnDestroy() {
        this.$unsubscribe.next();
        this.$unsubscribe.complete();
    }
}
