import { Component, inject } from '@angular/core';

import { ToastService } from '../../../services/toast-service';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-toaster',
  standalone: true,
  imports: [NgbToastModule],
  templateUrl: 'toaster.component.html',
  host: { class: 'toast-container position-fixed top-0 end-0 p-3', style: 'z-index: 1200' },
})
export class ToasterComponent {
  toastService = inject(ToastService);
}
